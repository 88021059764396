const i18n = {
  paid: 'Paid',
  notPaid: 'Not Paid',
  errorTitle: 'Error',
  errorSomethingWrong: 'Something went wrong. Please, refresh the page.',
  pageTitle: 'Invoice to Pay',
  deliveryPageTitle: 'Total Payment Due',
  invoice: 'Invoice',
  delivery: 'Delivery',
  total: 'TOTAL',
};

export default i18n;
