import { InvoiceControllerService } from '@texo-kit/api/payment';

import { useEffect, useState, useRef, useCallback } from 'react';

export const formatCurrency = (value = 1) => {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formatDate = (date: string) => {
  const d = new Date(date);
  const month = d.toLocaleString('default', { month: 'short' });

  return `${d.getDate()}-${month}-${d.getFullYear()}`;
};

export const axiosRecursiveGet = async (
  queryId: string,
  attempts = 10,
  timeout = 1000,
): Promise<any> => {
  try {
    const isDeliveryInvoice = window.location.pathname.endsWith('/d');
    const methodName = isDeliveryInvoice ? 'queryStatusForDelivery' : 'queryStatus';

    const response = await InvoiceControllerService[methodName]({ queryId });

    if (response?.invoiceQuery?.result) {
      return response;
    }

    throw new Error('Response does not contain "result" property');
  } catch (error) {
    if (attempts > 1) {
      await new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });

      return axiosRecursiveGet(queryId, attempts - 1);
    }

    throw new Error('Max attempts reached. Unable to get result.');
  }
};

export const useApiPolling = (
  queryId: string,
  invoiceQueryStatus,
  invoicePaymentStatus,
  showError: (error: string) => void,
  setInvoiceData,
  isDataLoaded,
  maxAttemptsCount = 1,
  delay = 5000,
) => {
  const maxAttempts = Math.max(maxAttemptsCount, 1);
  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isFetchingRef = useRef(false); // Synchronous tracking of fetching state
  const attemptsRef = useRef(0); // Synchronous tracking of attempts
  const [queryStatus, setQueryStatus] = useState(invoiceQueryStatus);
  const [paymentStatus, setPaymentStatus] = useState(invoicePaymentStatus);

  const isDeliveryInvoice = window.location.pathname.endsWith('/d');
  const methodName = isDeliveryInvoice ? 'queryStatusForDelivery' : 'queryStatus';
  const isPollingAllowed = !!(
    queryId &&
    queryStatus === 'IN_PROGRESS' &&
    paymentStatus === 'PENDING'
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await InvoiceControllerService[methodName]({ queryId });

      setInvoiceData(response);
      setQueryStatus(response?.invoiceQuery?.status || invoiceQueryStatus);
      setPaymentStatus(response?.payment?.status || invoicePaymentStatus);
      // Update attempts synchronously
      attemptsRef.current += 1;

      if (attemptsRef.current >= maxAttempts) {
        setMaxAttemptsReached(true);
        stopPolling();
      } else {
        scheduleNextFetch();
      }
    } catch (error) {
      showError(`Error fetching data: ${error.message || 'Unknown error'}`);
      stopPolling();
    }
  }, [
    maxAttempts,
    maxAttemptsReached,
    methodName,
    queryId,
    showError,
    invoiceQueryStatus,
    invoicePaymentStatus,
  ]);

  const scheduleNextFetch = useCallback(() => {
    if (maxAttemptsReached || !isFetchingRef.current) return;

    timeoutRef.current = setTimeout(() => {
      fetchData();
    }, delay);
  }, [delay, fetchData, maxAttemptsReached]);

  const startPolling = useCallback(() => {
    if (!isFetchingRef.current && isPollingAllowed) {
      isFetchingRef.current = true; // Synchronously set fetching state
      attemptsRef.current = 0; // Reset attempts for a fresh start
      fetchData();
    }
  }, [fetchData, isPollingAllowed]);

  const stopPolling = useCallback(() => {
    isFetchingRef.current = false;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (!isFetchingRef.current && !!queryId && !maxAttemptsReached) {
      startPolling();
    }
  }, [queryId, startPolling]);

  useEffect(() => {
    if (maxAttemptsReached || isDataLoaded) {
      stopPolling();
    }
  }, [maxAttemptsReached, isDataLoaded]);

  useEffect(() => {
    return () => {
      stopPolling(); // Use stopPolling for cleanup
    };
  }, [stopPolling]);

  useEffect(() => {
    setQueryStatus(invoiceQueryStatus);
    setPaymentStatus(invoicePaymentStatus);
  }, [invoiceQueryStatus, invoicePaymentStatus]);

  return {
    maxAttemptsReached,
  };
};

export const payerControllerRules = {
  required: 'Email is required',
  pattern: {
    value: /^[\w.-]+@[\w.-]+\.[\w]{2,4}$/g,
    message: 'Invalid email address',
  },
};
