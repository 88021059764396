import { createRoot } from 'react-dom/client';

import Landing from '@Views/Landing';

import './styles.scss';

const datadogClient = import('./datadog');

datadogClient.then((logs) => logs.default.init());

const root = createRoot(document.getElementById('app') as HTMLElement);

root.render(
  <div>
    <Landing />
  </div>,
);
