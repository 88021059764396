import { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';

import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

import { InvoiceControllerService, PayabliProxyControllerService } from '@texo-kit/api/payment';

import { BlockUI } from 'primereact/blockui';

import { InvoiceData, PaymentData, SelectedData } from '@Common/types';
import { formatCurrency, formatDate, useApiPolling } from '@Common/utils';

import i18n from './Landing.i18n';

import './Landing.styles.scss';

const PaymentForm = lazy(() => import('@Components/PaymentForm/PaymentForm'));
const InvoiceTable = lazy(() => import('@Components/InvoiceTable/InvoiceTable'));
const InvoiceInfo = lazy(() => import('@Components/InvoiceInfo/InvoiceInfo'));
const SuccessOverlay = lazy(() => import('@Components/SuccessOverlay/SuccessOverlay'));
const ErrorOverlay = lazy(() => import('@Components/ErrorOverlay/ErrorOverlay'));
const Header = lazy(() => import('@Components/Header/Header'));

const messageContent = (
  <div className="flex flex-row">
    <div className="column align-content-center mr-3">
      <i
        className="pi pi-info-circle"
        style={{ width: '21px', height: '21px', fontSize: '21px' }}
      />
    </div>
    <div className="column">
      <p className="m-0">
        The &apos;Total&apos; amount shown includes all applicable charges and taxes to be paid for
        your delivery.
      </p>
      <p className="m-0">
        The detailed Delivery Receipt document is attached to the email with payment instructions.
        Feel free to refer to it if you have any questions.
      </p>
    </div>
  </div>
);

export function Landing() {
  const isDeliveryInvoice = window.location.pathname.endsWith('/d');
  const prevInvoiceIdRef = useRef(null);
  const [invoiceID, setInvoiceID] = useState<string>('');
  const [queryId, setQueryId] = useState<string>('');
  const [invoiceData, setInvoiceData] = useState<InvoiceData | null>(null);
  const [queryStatus, setQueryStatus] = useState<string>('');
  const [paymentInfo, setPaymentInfo] = useState<PaymentData>({});
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [attemptsCountReached, setAttemptsCountReached] = useState<boolean>(false);
  const [paypointData, setPaypointData] = useState<any>({ Credentials: [] });
  const [fees, setFees] = useState<any>({
    card: 0,
    ach: 0,
  });
  const [selectedData, setSelectedData] = useState<SelectedData>({
    products: [],
    totalAmount: '',
    invoiceNumber: '',
    invoiceToCustomer: '',
    invoiceToBillAddress: '',
    invoiceDueDate: '',
    deliveryNumber: '',
    deliveryToCustomer: '',
    deliveryToBillAddress: '',
    deliveryDueDate: '',
    payerEmail: '',
    customerId: '',
    dwCustomerId: '',
  });

  const toastMsg = useRef<Toast>(null);

  const getPaymentStatus = (status: string | undefined) => {
    switch (status) {
      case 'COMPLETED':
        return i18n.paid;
      case 'PENDING':
        return i18n.notPaid;
      default:
        return '-';
    }
  };

  const showError = (errText: string) => {
    if (toastMsg.current) {
      toastMsg.current.show({
        severity: 'error',
        summary: i18n.errorTitle,
        detail: errText,
        life: 10000,
      });
    }
  };

  const handlePaymentSuccess = (res: any) => {
    setPaymentInfo((prev: PaymentData) => ({
      ...prev,
      status: 'COMPLETED',
      paymentReferenceId: res.responseData.referenceId,
    }));
  };

  const handlePaymentError = (err: any) => {
    setPaymentInfo((prev: PaymentData) => ({
      ...prev,
      status: err.responseText,
      message: err.responseData.resultText,
    }));
  };

  useEffect(() => {
    const invoice =
      window.location.pathname.split('invoice/').pop()?.toString().replace('/d', '') || '';

    setInvoiceID(invoice);
  }, []);

  const getQueryId = async () => {
    if (invoiceID) {
      let res;

      if (isDeliveryInvoice) {
        res = await InvoiceControllerService.queryDelivery({ deliveryId: invoiceID });
      } else {
        res = await InvoiceControllerService.queryInvoice({ invoiceId: invoiceID });
      }

      if (res) {
        const { invoiceQuery, payment } = res;

        setQueryStatus(invoiceQuery.status);
        setQueryId(invoiceQuery.queryId);
        setPaymentInfo(payment as PaymentData);

        if (invoiceQuery.status === 'SUCCESS') {
          setInvoiceData(res);
        }
      } else {
        console.error('Cant handle response', res);
      }
    } else {
      console.error('Cant parse URL to get ID');
    }
  };

  useEffect(() => {
    if (invoiceID && invoiceID !== prevInvoiceIdRef.current) {
      prevInvoiceIdRef.current = invoiceID;

      getQueryId().catch((err) => {
        console.error(err);
      });
    }
  }, [invoiceID]);

  useEffect(() => {
    const entryPoint = invoiceData?.invoiceQuery?.result?.customerPaypoint || '';

    if (!entryPoint) return;
    PayabliProxyControllerService.queryPaypointDetails({ paypointId: entryPoint })
      .then((res) => {
        setPaypointData(res?.responseData?.Paypoint);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [invoiceData]);

  const paymentStatus = paymentInfo?.status;

  const { maxAttemptsReached } = useApiPolling(
    queryId,
    queryStatus,
    paymentStatus,
    showError,
    setInvoiceData,
    isDataLoaded,
    5,
    5000,
  );

  useEffect(() => {
    const result = invoiceData?.invoiceQuery && invoiceData.invoiceQuery.result;

    if (invoiceData !== null && result) {
      setIsDataLoaded(true);
      setSelectedData({
        ...result,
        products: result.items || [],
        totalAmount: result.invoiceAmount || result.deliveryAmount,
        invoiceDueDate: formatDate(result.invoiceDueDate || result.deliveryDueDate),
      });
    }
  }, [invoiceData]);

  useEffect(() => {
    if (paypointData && paypointData.Credentials.length) {
      setFees({
        card: paypointData.Credentials.filter((e: { Service: string }) => e.Service === 'card')[0]
          .CfeeFloat,
        ach: paypointData.Credentials.filter((e: { Service: string }) => e.Service === 'ach')[0]
          .CfeeFloat,
      });
    }
  }, [paypointData]);

  const handledTextType = isDeliveryInvoice ? i18n.delivery : i18n.invoice;
  const handledTtitleByType = isDeliveryInvoice ? i18n.deliveryPageTitle : i18n.pageTitle;
  const paymentFormDisability = isDeliveryInvoice
    ? !isDataLoaded
    : selectedData?.products?.length === 0;
  const isPaypointDataLoaded = paypointData?.Credentials?.length > 0;

  const renderOverlay = useCallback(() => {
    if (!isDataLoaded && !maxAttemptsReached) {
      return <ProgressSpinner />;
    }

    if (maxAttemptsReached) {
      return (
        <ErrorOverlay
          title="Oops! Something went wrong."
          pageDescription="Maximum attempts reached. Unable to retrieve the invoice data result. Please try again later."
        />
      );
    }
  }, [isDataLoaded, maxAttemptsReached]);

  const isPaymentCompleted = paymentInfo.status === 'COMPLETED' || paymentInfo.status === 'FAILED';
  const showLoadingState = (!isDataLoaded || !isPaypointDataLoaded) && !isPaymentCompleted;

  return (
    <div className="flex flex-column h-full max-h-full min-h-full">
      <Header />
      <BlockUI
        blocked={showLoadingState}
        template={renderOverlay}
        // fullScreen
        containerClassName="h-full max-h-full min-h-full">
        <div className="app-l-main flex flex-column" id="wrapper">
          <Suspense fallback={<ProgressSpinner animationDuration="3" />}>
            <div className={showLoadingState ? 'opacity-40' : ''}>
              {paymentInfo.status === 'COMPLETED' && (
                <SuccessOverlay transactionId={paymentInfo.paymentReferenceId || '–'} />
              )}
              {paymentInfo.status === 'Declined' && <ErrorOverlay />}
              <main className="app-l-container pt-5 pb-6 flex-1 flex flex-column">
                <h1 className="text-2xl mt-0">{handledTtitleByType}</h1>
                <div className="flex gap-5 flex-1">
                  <div className="w-full">
                    <h3 className="text-lg mb-4">
                      {`${handledTextType}: ${selectedData.invoiceNumber || selectedData.deliveryNumber || '–'}`}
                    </h3>
                    <InvoiceInfo
                      paypointData={paypointData}
                      selectedData={selectedData}
                      status={getPaymentStatus(paymentInfo.status)}
                    />
                    {isDeliveryInvoice ? (
                      <>
                        <Message
                          severity="info"
                          content={messageContent}
                          className="custom-info-message"
                        />
                        <div className="delivery-total w-full flex flex-row justify-content-between mt-3">
                          <div className="column">{i18n.total}</div>
                          <div className="column">{formatCurrency(+selectedData.totalAmount)}</div>
                        </div>
                      </>
                    ) : (
                      <InvoiceTable
                        items={selectedData?.products}
                        totalAmount={+selectedData.totalAmount}
                        loading={!isDataLoaded}
                      />
                    )}
                  </div>
                  <PaymentForm
                    isDisabled={paymentFormDisability}
                    invoiceID={invoiceID}
                    payabliEntryPoint={invoiceData?.invoiceQuery?.result?.customerPaypoint || ''}
                    payabliPublicToken={invoiceData?.paymentIntegration?.publicToken || ''}
                    invoiceNumber={selectedData.invoiceNumber || selectedData.deliveryNumber}
                    customerId={selectedData.customerId}
                    dwCustomerId={selectedData.dwCustomerId}
                    customerName={selectedData.invoiceToCustomer || selectedData.deliveryToCustomer}
                    totalAmount={selectedData.totalAmount}
                    fees={fees}
                    payerEmail={selectedData.payerEmail}
                    onPaymentSuccess={(res) => handlePaymentSuccess(res)}
                    onPaymentError={(err) => handlePaymentError(err)}
                  />
                </div>
              </main>
            </div>
            <Toast ref={toastMsg} />
          </Suspense>
        </div>
      </BlockUI>
    </div>
  );
}

export default Landing;
